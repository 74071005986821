<template>
  <div class="">
    <!-- <pre>{{ JSON.stringify(currentWallpapersMark, null, 2) }}</pre> -->
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h2 class="mb-0">รูปอนุมัติไปแล้ว</h2>
      <div v-if="listData.length > 0">
        <b-button v-if="!startMarkWallpaper" variant="warning" @click="clickMarkWallpaper">
          <feather-icon icon="BookmarkIcon" class="mr-50" />
          <span class="align-middle">ปักหมุดรูปภาพ</span>
        </b-button>
        <b-button-group v-else>
          <b-button variant="success" @click="clickConfirmEditMark">
            ยืนยัน
          </b-button>
          <b-button variant="danger" @click="clickCancelEditMark">
            ยกเลิก
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div v-if="!loading">
      <b-row>
        <b-col sm="6" md="4" lg="3" v-for="(item, index) in listData" :key="item.id" class="mt-2">
          <div class="position-relative">
            <ImageActionCard
              :item="item"
              :currentItemLoading="currentItemLoading"
              @onClickMoreTransactionReview="onClickMoreTransactionReview"
              @onClickPreviewImage="onClickPreviewImage"
              @onClickMoreDetailsImage="onClickMoreDetailsImage"
              @onClickResendNotification="onClickResendNotification"
            />
            <!-- <b-button class="position-absolute">test</b-button> -->
            <div class="container-marker-wallpaper" v-if="startMarkWallpaper">
              <div class="backdrop"></div>
              <b-button :variant="variantCurrentSelectMark(item)" @click="markWallpaper(item)">
                <feather-icon icon="BookmarkIcon" class="mr-50" />
                <span>{{ orderCurrentWallpaperMark(item) }}</span>
              </b-button>
            </div>
          </div>
          <!-- <pre>{{ JSON.stringify(item.bookmark_order, null, 2) }}</pre> -->
        </b-col>
      </b-row>
      <h2 v-if="listData.length <= 0" class="text-center text-muted mt-5">
        ยังไม่มีรูปที่ถูกอนุมัติตอนนี้
      </h2>
    </div>

    <!-- <pre>{{ JSON.stringify(listData, null, 2) }}</pre> -->

    <BoxContainerLoading :loading="loading" />
    <b-modal
      id="modal-preview-images"
      ref="modal-preview-images"
      size="xl"
      hide-footer
      body-class="p-0"
      title="รูปทั้งหมด"
    >
      <ImagesPreviewSlide :listData="imagePreviewList" />
    </b-modal>

    <b-modal id="modal-review-transaction" ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
      <TimelineReviewWidget :listData="listReview" />
    </b-modal>
  </div>
</template>

<script>
import ImageActionCard from '../components/ImageActionCard.vue'
import TimelineReviewWidget from '../../../../../widgets/TimelineReviewWidget.vue'
import ImagesPreviewSlide from '../components/ImagesPreviewSlide.vue'
import BoxContainerLoading from '@/widgets/BoxContainerLoading.vue'

export default {
  components: { ImageActionCard, TimelineReviewWidget, ImagesPreviewSlide, BoxContainerLoading },
  data() {
    return {
      listData: [],
      listReview: [],
      imagePreviewList: [],
      loading: false,
      currentItemLoading: null,
      startMarkWallpaper: false,
      currentWallpapersMark: [],
      oldCurrentWallpaperMark: [],
    }
  },
  async created() {
    this.queryData()
  },
  computed: {},
  methods: {
    async queryData() {
      this.loading = true
      const res = await this.api.get('api/admin/admin-image-approve?status=approve&filterType=image')
      // console.log('[RES]: getImageApprove', res)
      const filterBookmarkOrderList = [...res].filter(v => v.bookmark_order).map(v => v.id)
      this.oldCurrentWallpaperMark = [...filterBookmarkOrderList]
      this.currentWallpapersMark = [...filterBookmarkOrderList]
      this.listData = res
      this.loading = false
    },
    onClickMoreTransactionReview(data) {
      // console.log('onClickMoreTransactionReview => ', data)
      this.listReview = [...data]
      this.$refs['modal-review-transaction'].show()
    },
    onClickPreviewImage(data) {
      // console.log('onClickPreviewImage => ', data)
      this.imagePreviewList = data
      this.$refs['modal-preview-images'].show()
    },
    onClickMoreDetailsImage(data) {
      // console.log('onClickMoreDetailsImage => ', data)
      this.$router.push({ name: 'images-management-details', params: { id: data.id ?? 0, canReview: false } })
    },
    async onClickResendNotification(data) {
      this.currentItemLoading = data.id
      await this.api.post('/api/admin/notification-data/resend-topic-image-data', { topic_image_data: data.id })
      this.currentItemLoading = null
    },
    clickMarkWallpaper() {
      this.startMarkWallpaper = true
    },
    async clickConfirmEditMark() {
      // console.log('clickConfirmEditMark')
      this.startMarkWallpaper = false
      this.loading = true
      const res = await this.api.post('api/admin/admin-topic-images/bookmark', {
        new_bookmark_order: [...this.currentWallpapersMark],
      })
      await this.queryData()
      // console.log('res', res)
      this.loading = false
    },
    clickCancelEditMark() {
      // console.log('clickCancelEditMark')
      this.currentWallpapersMark = [...this.oldCurrentWallpaperMark]
      this.startMarkWallpaper = false
    },
    markWallpaper(item) {
      // console.log('select item mark', item)
      const currentList = [...this.currentWallpapersMark]
      const indexItem = currentList.findIndex(v => v === item.id)

      if (indexItem > -1) {
        currentList.splice(indexItem, 1)
        this.currentWallpapersMark = [...currentList]
      } else {
        this.currentWallpapersMark = [...this.currentWallpapersMark, item.id]
      }
    },
    variantCurrentSelectMark(item) {
      return this.currentWallpapersMark.find(v => v === item.id) ? 'warning' : 'secondary'
    },
    orderCurrentWallpaperMark(item) {
      const orderIndex = [...this.currentWallpapersMark].findIndex(v => v === item.id)
      if (orderIndex < 0) return '-'
      return orderIndex + 1
    },
  },
}
</script>

<style lang="scss" scoped>
.container-marker-wallpaper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .backdrop {
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.3;
    z-index: 1;
  }
  button {
    z-index: 2;
  }
}
</style>
